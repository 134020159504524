<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <h4 class="mt-1">เปลี่ยนรหัสผ่าน</h4>
                <Message v-if="errorMessage" severity="error">{{ errorMessage }}</Message>
                <div class="p-fluid">
                    <div class="field">
                        <label for="username">บัญชีผู้ใช้งาน</label>
                        <InputText :value="auth.user.username" :readonly="true" />
                    </div>
                    <div class="field">
                        <label for="email">รหัสผ่าน-ปัจจุบัน</label>
                        <Password v-model="form.password.oldPassword" toggleMask :feedback="false" />
                    </div>
                    <div class="field">
                        <label for="name">รหัสผ่าน-ใหม่</label>
                        <Password v-model="form.password.newPassword" toggleMask :feedback="false" />
                    </div>
                    <div class="field">
                        <label for="name">ยืนยัน-รหัสผ่าน-ใหม่</label>
                        <Password v-model="form.password.confirm" toggleMask :feedback="false" />
                    </div>
                </div>
                <Button :label="$t('common.btn.saveNew')" @click="savePassword"></Button>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, toRefs } from 'vue';
// import { useStore } from 'vuex';
export default {
    setup() {
        const state = reactive({
            errorMessage: '',
            isLoading: false,
            form: {
                username: '',
                password: {
                    oldPassword: '',
                    newPassword: '',
                    confirm: '',
                },
            },
        });

        // const store = useStore();
        // const auth = computed(() => store.state.auth);

        const auth = null;

        const savePassword = () => {
            //console.log(state.form);
        };

        return { ...toRefs(state), savePassword, auth };
    },
};
</script>

<style lang="scss" scoped></style>
